<template>
  <div class="auth-wrapper">
    <div class="row page-not-found">
      <div class="text-center logo-home">
        <a href="/">
          <!-- <img src="../assets/images/logo/logo_dark.png" class="img-fluid" /> -->
          <img
            class="img-fluid"
            src="../assets/images/logo/logo_register.svg"
          />
        </a>
      </div>

      <div class="not-fount-card col bg-md-transparent">
        <h5 class="title">{{ $t("utils.page_not_found") }}</h5>
        <div class="text-404">404</div>
        <a class="to-home be-btn primary blue w-100 lg" href="/">
          {{ $t("utils.go_to_home_page") }}
        </a>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/layouts/Footer";

export default {
  name: "PageNotFound",
  components: { Footer },
};
</script>

<style scoped></style>
